// RESPONSIVE NAV
var mainNav = function(menuList, menuBtn) {

  var s,
    GrNav = {

      settings: {
        menuList: $(menuList),
        menuBtn: $(menuBtn),
        windowSize: 960,
      },

      init: function() {
        s = this.settings;
        this.navClick();
      },

      // slide the menu on click
      navClick: function() {
        s.menuBtn.on('click', function(evt) {
          evt.preventDefault();
          s.menuList.slideToggle(300);
        });
      }

    };

  GrNav.init();

};

mainNav('#js-nav', '#menu');

$('#nav-toggle').on('click', function() {
    $(this).toggleClass('is-active');
});

// SVG FALLBACK
(function() {
  if(!Modernizr.svg) {
    $('img[src*="svg"]').attr('src', function() {
      return $(this).attr('src').replace('.svg', '.png');
    });
  }
}());

// PLACEHOLDER FALLBACK

(function() {
  if(!('placeholder' in document.createElement('input')) || !('placeholder' in document.createElement('textarea'))) {
    $(document).ready( function() {
        $('[placeholder]').focus(function() {
          var input = $(this);
          if (input.val() == input.attr('placeholder')) {
            input.val('');
            input.removeClass('placeholder');
          }
        }).blur(function() {
          var input = $(this);
          if (input.val() === '' || input.val() == input.attr('placeholder')) {
            input.addClass('placeholder');
            input.val(input.attr('placeholder'));
          }
        }).blur().parents('form').submit(function() {
          $(this).find('[placeholder]').each(function() {
            var input = $(this);
            if (input.val() == input.attr('placeholder')) {
              input.val('');
            }
          });
        });
    });
  }
}());


// PRINT BUTTON

(function() {
    $('.js-print').on('click', function() {
        window.print();
    });
}());
